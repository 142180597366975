import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import LocalizationProvider from "./hooks/localize";

Sentry.init({
    dsn: "https://988b9c862da710f7e3e04b693c5b2da6@o126375.ingest.us.sentry.io/4508047039266816",
    tracesSampleRate: 1,

    integrations: [Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }), Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
    })],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1
})

const htmlElement = document.querySelector("html");
const lng = htmlElement?.lang || "en";
startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <LocalizationProvider lng={lng}>
        <RemixBrowser />
      </LocalizationProvider>
    </StrictMode>
  );
});